import './Declutter.css';

function App() {
  return (
    <div className="App">
      <h1>a website for the Hansf<span style={{textDecoration: 'line-through'}}>org</span>ords</h1>
    </div>
  );
}

export default App;
