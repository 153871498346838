import './Declutter.css';

import { useState } from 'react';

function Declutter() {
  const services = [
    {
      name: 'Space & Time',
      website: 'https://spaceandtime.com.au',
      focus: 'hands-on',
      consult: 'yes',
      disposal: 'no'
    }, {
      name: 'Bless This Mess',
      website: 'https://blessthismess.com.au',
      focus: 'hands-on',
      consult: 'yes',
      disposal: '? - the terms & conditions hint yes, but unclear'
    }, {
      name: 'The Art of Decluttering',
      website: 'https://www.theartofdecluttering.com.au',
      focus: 'coaching and/or hands-on',
      consult: '?',
      disposal: 'takes a car load of donations to charity'
    }, {
      name: 'Dr Declutter',
      website: 'https://drdeclutter.com.au',
      focus: 'coaching and/or hands-on',
      consult: '?',
      disposal: '?'
    }, {
      name: 'Changes in Between',
      website: 'https://www.changesinbetween.com',
      focus: 'coaching and counselling',
      consult: '?',
      disposal: '?'
    }, {
      name: 'The Decluttering Co',
      website: 'https://thedeclutteringco.com.au',
      focus: 'hands-on',
      consult: '?',
      disposal: 'yes - charity donations, recycling, and will assist in selling items'
    }, {
      name: 'Time to Tidy',
      website: 'https://www.timetotidy.com.au',
      focus: 'hands-on',
      consult: 'yes',
      disposal: 'yes - charity donations and recycling, possibly additional fees'
    }
  ]
  return (
    <div className="App">
      <h1>decluttering suggestions</h1>

      <div className="intro">
        <p>Happy birthday Maman! In addition to getting you tax-free access to
        your superannuation (you're welcome), we're delighted to gift you some
        decluttering assistance.</p>
        <p>Rather than decide on your behalf which consultants would suit you,
        we've compiled a list of some options, and listed some helpful summary
        points.</p>
        <p>Have a peruse, and when you've decided which organisation you'd like
        to use, let us know so we can arrange your session.</p>
        <p>So much love 💝</p>
      </div>
      <table>
        <tbody>
          {services.map((service) => <NameRow service={service} />)}
        </tbody>
      </table>
    </div>
  );
}

function NameRow({service}) {
  const [open, setOpen] = useState(false);
  return (
    <tr>
      <td>
        <div className="row">
          <span className="name">{service.name}</span>
          <span className="more" onClick={() => setOpen(!open)}>{open ? '▲' : '▼'}</span>
        </div>
        {open ? <DetailsRow service={service} /> : ''}
      </td>
    </tr>
  )
}

function DetailsRow({service}) {
  return (
    <div>
      <ul>
        <li><a href={service.website}>Website ↗</a></li>
        <li>Focus: {service.focus}</li>
        <li>Initial consultation: {service.consult}</li>
        <li>Disposal: {service.disposal}</li>
      </ul>
    </div>
  )
}

export default Declutter;
